import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from 'pages/LoginPage';
import PrivateRoute from 'utils/privateRoute';
import Loader from 'ui-lib/loader';
import { ReactQueryConfigProvider } from 'react-query';

const queryConfig = {
  queries: {
    staleTime: 0,
    retry: 0
  }
};

const App = React.lazy(() => import('pages/AppPage'));

function Root() {
  return (
    <React.Suspense fallback={<Loader />}>
      <ReactQueryConfigProvider config={queryConfig}>
        <Router>
          <Switch>
            <Route path="/login" exact>
              <Login />
            </Route>
            <PrivateRoute path="/">
              <App />
            </PrivateRoute>
            {/* <PrivateRoute path="/app">
          <App />
        </PrivateRoute> */}
          </Switch>
        </Router>
      </ReactQueryConfigProvider>
    </React.Suspense>
  );
}

export default Root;
