import styled from 'styled-components';

const Label = styled.label`
  display: inline-block;
  font-weight: 300;
  font-size: ${(p) => p.theme.typography.size.small};
  color: ${(p) => p.theme.color.grey[900]};
  padding-bottom: ${(p) => p.theme.gap.S};
`;

export default Label;
