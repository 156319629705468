import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';

const getWeight = (weight: 'regular' | 'solid'): IconPrefix => {
  if (weight === 'regular') {
    return 'far';
  }
  return 'fas';
};

export type UuIcon = IconName | 'uulogo' | 'uulogout';

interface Props {
  type: UuIcon;
  className?: string;
  weight?: 'regular' | 'solid';
}

//// COMPONENT ////////////////////////////////////////////////////////////////

const Icon = ({ weight = 'solid', type, className = '' }: Props) => (
  <FontAwesomeIcon className={className} icon={[getWeight(weight), type as IconName]} />
);

export default Icon;
