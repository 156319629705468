import axios from 'axios';
import { config, AUTH_TOKEN } from 'utils/constants';

// Set config defaults when creating the instance
const uuInstance = axios.create({
  baseURL: config.API_PATH
});

export const setAuthToken = (token: string) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
    //applying token
    uuInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    //deleting the token from header
    delete uuInstance.defaults.headers.common['Authorization'];
  }
};

const currentToken = localStorage.getItem(AUTH_TOKEN);

if (currentToken) {
  setAuthToken(currentToken);
}

// // Add a request interceptor
// Axios.interceptors.request.use(
//   function (config) {
//     const userToken = localStorage.getItem(AUTH_TOKEN);
//     // Do something before request is sent
//     return {
//       ...config,
//       headers: {
//         ...config.headers,
//         // Legacy
//         Authorisation: userToken,
//         // Token
//         Authorization: userToken
//       }
//     };
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

export default uuInstance;
