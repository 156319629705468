import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Reset } from 'styled-reset';
import GlobalStyle from './GlobalStyles';
import { theme } from './theme';
import { IntlProvider } from 'react-intl';
import messages from 'i18';
import './initIcons';
import Root from './Root';

const locale = 'ro';
// const queryConfig = {
//   suspense: true
// };

ReactDOM.render(
  // react-select throws warning for this .. grrr 😠
  // <React.StrictMode>
  <IntlProvider locale={locale} messages={messages[locale]}>
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <Root />
    </ThemeProvider>
  </IntlProvider>,
  // </React.StrictMode>
  document.getElementById('root')
);
