import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
html {
  font-size: ${theme.typography.size.base};
  font-family: ${theme.typography.fontFamily};
  color: ${theme.color.grey[900]};
  box-sizing: border-box;
  background: ${theme.color.grey[50]};
}

*, *:before, *:after {
  box-sizing: inherit;
}

 a {
  text-decoration: none;
}

body { 
  background: ${theme.color.grey[50]};
  color: ${theme.color.grey[900]}
}

p, ul, ol, pre, table, blockquote {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
ul ul, ol ol, ul ol, ol ul {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

hr, .hr {
  border: 1px solid lightgray;
  margin: 20px 0;
}

ul {
  list-style: none;
}

svg {
  /* font-size: inherit !important; */
  font-size: inherit;
}

code {
  font-family: monospace;
}

`;

export default GlobalStyle;
