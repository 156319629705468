import uuInstance from 'queries/uu.instance';

interface AccountLoginParams {
  userId: string;
  accountId: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  tokenType: string;
  expiresIn: string;
}

export const getTokenWithAccount = async ({
  userId,
  password,
  accountId
}: AccountLoginParams) => {
  const data = uuInstance.post<LoginResponse>('/v2/account-tokens', {
    accountId,
    password,
    userId
  });
  return data;
};
