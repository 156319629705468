import React from 'react';
import { useHistory } from 'react-router-dom';
import LoginForm from 'components/loginForm';
import styled from 'styled-components';

const PageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 23rem 1fr;
  grid-template-rows: 100px max-content max-content 1fr;
  gap: 1px 1px;
  grid-template-areas: '. . .' '. form .' '. links .' '. . .';
`;

const StyledLoginForm = styled(LoginForm)`
  grid-area: form;
  box-shadow: ${(p) => p.theme.depth[100]};
`;
const LoginLinks = styled.div`
  grid-area: links;
  margin-top: ${(p) => p.theme.gap.XL};
  text-align: center;
  color: ${(p) => p.theme.color.grey[500]};
`;

function Login() {
  const history = useHistory();
  // React.useEffect(() => {
  //   const token = localStorage.getItem(AUTH_TOKEN);
  //   if (token) {
  //     try {
  //       const dec = jwtDecode(token);
  //       if (dec) {
  //         history.replace('/app');
  //       }
  //       console.log('dec  =', dec);
  //     } catch (error) {
  //       console.log('error', error);
  //     }
  //   }
  // }, []);
  return (
    <PageWrapper>
      <StyledLoginForm
        onCancelClick={() => {
          history.push('/');
        }}
      />
      <LoginLinks></LoginLinks>
    </PageWrapper>
  );
}

export default Login;
