import * as React from 'react';
import styled, { css } from 'styled-components';
import { darken, rem } from 'polished';
import Icon, { UuIcon } from './icon';

const smallPadding = css`
  padding: ${(p) => p.theme.gap.XS} ${(p) => p.theme.gap.M};
`;
const largePadding = css`
  padding: ${(p) => p.theme.gap.S} ${(p) => p.theme.gap.M};
`;

const primaryStyling = css`
  border: 1px solid ${(p) => p.theme.color.primary[900]};
  color: ${(p) => p.theme.color.white};
  background: ${(p) => p.theme.color.primary[600]};

  &:hover:not(:disabled) {
    cursor: pointer;
    color: ${(p) => p.theme.color.white};
    background: ${(p) => darken(0.05, p.theme.color.primary[600])};
  }

  :disabled {
    color: ${(p) => p.theme.color.grey[500]};
    background: ${(p) => p.theme.color.grey[200]};
    border: 1px solid transparent;
    svg {
      color: ${(p) => p.theme.color.grey[400]};
    }
  }
`;

const secondaryStyling = css`
  border: 1px solid ${(p) => p.theme.color.primaryColor};
  color: ${(p) => p.theme.color.primary[800]};
  background: ${(p) => p.theme.color.white};

  svg {
    color: ${(p) => p.theme.color.primary[500]};
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    color: ${(p) => p.theme.color.grey[900]};
    background: ${(p) => p.theme.color.primary[50]};
  }

  :disabled {
    color: ${(p) => p.theme.color.grey[500]};
    background: ${(p) => p.theme.color.grey[100]};
    border: 1px solid ${(p) => p.theme.color.grey[300]};
    svg {
      color: ${(p) => p.theme.color.grey[500]};
    }
  }
`;

const dangerStyling = css`
  border: 1px solid ${(p) => p.theme.color.uu.danger};
  color: ${(p) => p.theme.color.uu.danger};
  background: ${(p) => p.theme.color.white};

  svg {
    color: ${(p) => p.theme.color.uu.danger};
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    color: ${(p) => p.theme.color.white};
    background: ${(p) => darken(0.03, p.theme.color.uu.danger)};
    svg {
      color: ${(p) => p.theme.color.white};
    }
  }

  :disabled {
    color: ${(p) => p.theme.color.grey[500]};
    background: ${(p) => p.theme.color.grey[50]};
    border: 1px solid ${(p) => p.theme.color.grey[300]};
    svg {
      color: ${(p) => p.theme.color.grey[500]};
    }
  }
`;

const dangerColorsStyling = css`
  color: ${(p) => p.theme.color.uu.danger};
  background: ${(p) => p.theme.color.white};

  svg {
    color: ${(p) => p.theme.color.uu.danger};
  }
`;

const linkStyling = css`
  display: inline-block;
  padding: 0;
  border: 0;
  background: transparent;
  color: ${(p) => p.theme.color.primary[800]};

  svg {
    color: ${(p) => p.theme.color.primary[600]};
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    color: ${(p) => p.theme.color.primary[900]};
    text-decoration: underline;
  }

  :disabled {
    color: ${(p) => p.theme.color.grey[500]};
    svg {
      color: ${(p) => p.theme.color.grey[500]};
    }
  }
`;

const ButtonStyling = styled.button<Props>`
  border-radius: 3px;
  outline-color: ${(p) => p.theme.color.primaryColor};
  display: inline-flex;
  align-items: center;

  font-size: ${(p) =>
    p.small ? p.theme.typography.size.small : p.theme.typography.size.base};

  ${(p) => (p.small ? smallPadding : largePadding)};
  ${(p) => p.kind === 'primary' && primaryStyling};
  ${(p) => p.kind === 'secondary' && secondaryStyling};
  ${(p) => p.kind === 'link' && linkStyling};
  ${(p) => p.kind === 'danger' && dangerStyling};
  ${(p) => p.danger && dangerColorsStyling};

  svg {
    font-size: ${(p) => (p.small ? rem('11px') : rem('13px'))};
    ${(p) =>
      p.iconPosition === 'after'
        ? css`
            margin-left: ${p.theme.gap.XS};
          `
        : css`
            margin-right: ${p.theme.gap.XS};
          `}
  }
`;

interface Props {
  small?: boolean;
  danger?: boolean;
  iconPosition?: 'before' | 'after';
  iconBefore?: UuIcon;
  iconAfter?: UuIcon;
  kind?: 'primary' | 'secondary' | 'danger' | 'link';
}

const Button = ({
  children,
  kind = 'secondary',
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <ButtonStyling kind={kind} {...props}>
      {props.iconBefore && <Icon type={props.iconBefore} />}
      {children} {props.iconAfter && <Icon type={props.iconAfter} />}
    </ButtonStyling>
  );
};

export default Button;
