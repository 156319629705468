import { rem, darken } from 'polished';

const grey = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121'
};

const primary = {
  '50': '#e0f6fc',
  '100': '#b3e8f8',
  '200': '#80d9f3',
  '300': '#4dcaee',
  '400': '#26beea',
  '500': '#00b3e6',
  '600': '#00ace3',
  '700': '#00a3df',
  '800': '#009adb',
  '900': '#008bd5',
  A100: '#fcfeff',
  A200: '#c9eaff',
  A400: '#96d6ff',
  A700: '#7dcbff'
};

const uu = {
  orange: '#f7941e',
  yellow: '#ffde00',
  // success: darken(0.05, '#5cb85c')
  success: darken(0.05, 'forestgreen'),
  danger: '#d50000'
};

const color = {
  white: '#FFFFFF',
  grey,
  primaryColor: '#00B3E6',
  primary,
  uu
};

const depth = {
  100: '7px 7px 20px #dcdcdc, -7px -7px 20px #ffffff'
};

const gap = {
  XXS: rem('2px'),
  XS: rem('5px'),
  S: rem('10px'),
  M: rem('20px'),
  L: rem('30px'),
  XL: rem('45px'),
  XXL: rem('55px')
};

const typography = {
  fontFamily: "'Roboto', sans-serif",
  headerFontFamily: "'Raleway', sans-serif",
  size: {
    small: '13px',
    base: '15px',
    large: '17px'
  }
};

export const theme = {
  name: 'Default theme',
  gap,
  color,
  uu,
  depth,
  typography
};
