import React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface Props {
  iconPosition?: 'before' | 'after';
  small?: boolean;
  kind?: 'primary' | 'secondary';
}

type P = NavLinkProps & Props;

const Link = styled(({ iconPosition, small, kind, children, ...rest }: P) => (
  <NavLink {...rest}>{children}</NavLink>
))`
  font-size: ${(p) =>
    p.small ? p.theme.typography.size.small : p.theme.typography.size.base};
  color: ${(p) => p.theme.color.primary[600]};
  cursor: pointer;
  :visited {
    text-decoration: none;
  }

  svg {
    ${(p) =>
      p.iconPosition === 'after'
        ? css`
            margin-left: ${p.theme.gap.XXS};
          `
        : css`
            margin-right: ${p.theme.gap.XXS};
          `}
  }

  &:hover {
    color: ${(p) => darken(0.05, p.theme.color.primary[600])};
  }
`;

export default Link;
