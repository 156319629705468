export const AUTH_TOKEN = 'uu-ui.token';

export const ENTRY_URL = '/fleet/faz/report';

// export const API_PATH = '';
// https://medium.com/@a.carreras.c/development-and-production-variables-for-react-apps-c04af8b430a5
const prod = {
  API_PATH: '/api'
};
const dev = {
  API_PATH: ''
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
