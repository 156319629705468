import styled from 'styled-components';
import { rem } from 'polished';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.gap.M} ${(p) => p.theme.gap.L} /* @ts-ignore */
    ${(p) => p.theme.gap.L};
  /* background: ${(p) => p.theme.color.grey[50]};
  border: 1px solid ${(p) => p.theme.color.grey[200]}; */

  legend {
    font-weight: 300;
    font-size: ${rem('24px')};
    text-align: center;
    margin-top: ${(p) => p.theme.gap.M};
    margin-bottom: ${(p) => p.theme.gap.M};
    padding-left: ${(p) => p.theme.gap.S};
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  & ~ & {
    margin-top: ${(p) => p.theme.gap.L};
  }
`;

export const ControlGroup = styled.div`
  padding-top: ${(p) => p.theme.gap.L};
  button ~ button {
    margin-left: ${(p) => p.theme.gap.XS};
  }
`;

export const ErrorMessage = styled.div`
  font-size: ${(p) => p.theme.typography.size.base};
  padding-top: ${(p) => p.theme.gap.S};
  padding-left: ${(p) => p.theme.gap.S};
  color: ${(p) => p.theme.uu.orange};
`;

export default { Form, InputGroup, ControlGroup, ErrorMessage };
