import { library, IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';

import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fab, far, fas);

const fasLogoCuText: IconDefinition = {
  prefix: 'fas',
  iconName: 'uulogo' as IconName,
  icon: [
    256,
    256,
    [],
    'f0002',
    'M500 598v-124c0 0 0 0 0 0l0 0c0-68-55-123-124-123-68 0-123 55-123 123l0 0 0 0v371h-248v-371l0 0v0c0-205 166-371 371-371 205 0 371 166 371 371l0 0c0 0 0 0 0 0v124h-247z m247 247h248v-247h-248z m-654-948c0-29-21-42-44-42-23 0-44 13-44 42v56h16v-55c0-20 12-29 28-29s29 9 29 29v55h15v-56z m112 19v-59h-15v54c0 19-10 30-28 30-16 0-29-12-29-30v-54h-16v96h15v-14c8 12 21 16 33 16 24 0 40-15 40-39z m101 23v64h15v-146h-15v14c-8-10-20-16-36-16-27 0-48 21-48 50 0 29 21 50 48 50 16 0 28-6 36-16z m-68-34c0-20 14-36 34-36 20 0 34 16 34 36 0 20-14 36-34 36-21 0-34-16-34-36z m194 0c0-2 0-4 0-5h-78c3-21 17-32 35-32 12 0 22 5 30 12l8-10c-12-12-25-15-39-15-29 0-49 20-49 50 0 29 20 50 48 50 26 0 45-20 45-50z m-77 7h62c-2 18-14 30-31 30-17 0-29-13-31-30z m182-15c0-29-21-42-44-42-23 0-44 13-44 42v56h15v-55c0-20 12-29 29-29 17 0 29 9 29 29v55h15v-56z m185 19v-59h-16v54c0 19-10 30-27 30-17 0-30-12-30-30v-54h-15v54c0 19-10 30-28 30-16 0-30-12-30-30v-54h-15v96h15v-14c8 12 21 16 33 16 16 0 30-7 36-20 8 15 23 20 36 20 24 0 41-15 41-39z m123-11c0-29-21-50-49-50-15 0-27 6-35 16v-14h-15v147h15v-65c8 10 20 16 35 16 28 0 49-21 49-50z m-85 0c0-21 14-36 35-36 20 0 34 16 34 36 0 20-14 36-34 36-21 0-35-16-35-36z m118-48h-15v147h15v-147z m102 96v-14c-8 10-20 16-36 16-27 0-48-21-48-50 0-29 21-50 48-50 16 0 28 6 36 16v-14h15v96h-15z m-34-84c-20 0-34 16-34 36 0 20 14 36 34 36 20 0 35-16 35-36 0-21-15-36-35-36z m22 129c-4-7-11-12-20-12-8 0-15 5-19 12l-13-6c6-12 18-20 32-20 14 0 27 8 32 20l-12 6z'
  ]
};

library.add(fasLogoCuText);
