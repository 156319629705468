import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'ui-lib/button';
import { ControlGroup, ErrorMessage, Form, InputGroup } from 'ui-lib/forms';
import Input from 'ui-lib/input';
import Label from 'ui-lib/label';
import Link from 'ui-lib/link';
import { getTokenWithAccount } from 'queries/auth/getTokenWithAccount';
import { getTokenWithEmail } from 'queries/auth/getTokenWithEmail';
import { setAuthToken } from 'queries/uu.instance';
import { ENTRY_URL } from 'utils/constants';

interface Props {
  className?: string;
  onCancelClick?: () => void;
}

const ActionControlGroup = styled(ControlGroup)``;

const RecoverControlGroup = styled(ControlGroup)`
  text-align: center;
`;

const FormPicker = styled.div`
  padding: 0 0 ${(p) => p.theme.gap.L};
  width: 100%;
  display: flex;
`;

const FormWrapper = styled(Form)`
  padding: 0 0 ${(p) => p.theme.gap.M};
  background: ${(p) => p.theme.color.grey[50]};
  border: 1px solid ${(p) => p.theme.color.grey[200]};

  > :not(:first-child) {
    padding: 0 ${(p) => p.theme.gap.M} ${(p) => p.theme.gap.M};
  }
`;

const FormType = styled.button<{ isSelected: boolean }>`
  border: 0;
  background: ${(p) => p.theme.color.grey[50]};
  font-size: ${(p) => p.theme.typography.size.base};
  border-top: 2px solid
    ${(p) => (p.isSelected ? p.theme.color.primary[500] : p.theme.color.grey[300])};
  color: ${(p) => (p.isSelected ? p.theme.color.primary[900] : p.theme.color.grey[400])};
  padding-top: ${(p) => p.theme.gap.M};
  text-align: center;
  flex: 1 0 50%;
  cursor: pointer;

  :focus {
    outline: 0;
  }

  :hover {
    text-decoration: underline;
    border-top: 2px solid ${(p) => p.theme.color.primary[100]};
    color: ${(p) => p.theme.color.grey[700]};
  }
`;

function LoginForm(props: Props) {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [loginError, setLoginError] = React.useState('');
  const [formType, setFormType] = React.useState('account');

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      accountId: '',
      password: '',
      userId: '',
      email: ''
    }
  });
  const [mutateWithAccount] = useMutation(getTokenWithAccount);
  const [mutateWithEmail] = useMutation(getTokenWithEmail);

  return (
    <FormWrapper
      className={props.className}
      onSubmit={handleSubmit(async (loginFormData) => {
        if (formType === 'account') {
          try {
            const { data } = await mutateWithAccount(loginFormData);
            if (data && data.accessToken) {
              setAuthToken(data.accessToken);
              history.push(ENTRY_URL);
            }
          } catch (error) {
            setLoginError('User sau parola invalide');
          }
        } else {
          try {
            const { data } = await mutateWithEmail(loginFormData);
            if (data && data.accessToken) {
              setAuthToken(data.accessToken);
              history.push(ENTRY_URL);
            }
          } catch (error) {
            setLoginError('User sau parola invalide');
          }
        }
      })}
    >
      <FormPicker>
        <FormType
          onClick={() => setFormType('account')}
          type="button"
          isSelected={formType === 'account'}
        >
          {formatMessage({ id: 'loginForm.classicForm' })}
        </FormType>
        <FormType
          onClick={() => setFormType('email')}
          type="button"
          isSelected={formType === 'email'}
        >
          {formatMessage({ id: 'loginForm.emailForm' })}
        </FormType>
      </FormPicker>
      <legend>{formatMessage({ id: 'loginForm.title' })}</legend>

      {formType === 'account' && (
        <InputGroup>
          <Label htmlFor="accountId">{formatMessage({ id: 'loginForm.account' })}</Label>
          <Input
            autoComplete="none"
            autoFocus
            id="accountId"
            name="accountId"
            placeholder={formatMessage({ id: 'loginForm.accountPlaceholder' })}
            ref={register({
              required: formatMessage({ id: 'loginForm.required' })
            })}
          />
          {errors.accountId && errors.accountId.message && (
            <ErrorMessage>{errors.accountId.message}</ErrorMessage>
          )}
        </InputGroup>
      )}
      {formType === 'account' && (
        <InputGroup>
          <Label htmlFor="userId">{formatMessage({ id: 'loginForm.userId' })}</Label>
          <Input
            autoComplete="none"
            autoFocus
            id="userId"
            name="userId"
            placeholder={formatMessage({ id: 'loginForm.usernamePlaceholder' })}
            ref={register({
              required: formatMessage({ id: 'loginForm.required' })
            })}
          />
          {errors.email && errors.email.message && (
            <ErrorMessage>{errors.email.message}</ErrorMessage>
          )}
        </InputGroup>
      )}
      {formType === 'email' && (
        <InputGroup>
          <Label htmlFor="email">{formatMessage({ id: 'loginForm.email' })}</Label>
          <Input
            autoComplete="none"
            autoFocus
            id="email"
            name="email"
            placeholder={formatMessage({ id: 'loginForm.emailPlaceholder' })}
            ref={register({
              required: formatMessage({ id: 'loginForm.required' }),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: formatMessage({ id: 'loginForm.emailInvalid' })
              }
            })}
          />
          {errors.email && errors.email.message && (
            <ErrorMessage>{errors.email.message}</ErrorMessage>
          )}
        </InputGroup>
      )}
      <InputGroup>
        <Label htmlFor="password">{formatMessage({ id: 'loginForm.password' })}</Label>
        <Input
          autoComplete="no-auto"
          id="password"
          name="password"
          type="password"
          ref={register({ required: formatMessage({ id: 'loginForm.required' }) })}
        />
      </InputGroup>
      <ActionControlGroup>
        <Button type="submit" kind="primary">
          Login
        </Button>
      </ActionControlGroup>
      {loginError && <ErrorMessage>{loginError}</ErrorMessage>}
      <RecoverControlGroup>
        <Link to="/" small>
          {formatMessage({ id: 'loginForm.recoverPassword' })}
        </Link>
      </RecoverControlGroup>
    </FormWrapper>
  );
}

export default LoginForm;
