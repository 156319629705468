import uuInstance from 'queries/uu.instance';

interface EmailLoginParams {
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  tokenType: string;
  expiresIn: string;
}

export const getTokenWithEmail = async ({ email, password }: EmailLoginParams) => {
  const data = uuInstance.post<LoginResponse>('/v2/email-tokens', {
    email,
    password
  });
  return data;
};
