import styled from 'styled-components';
import { rem } from 'polished';

const Input = styled.input`
  border: 1px solid ${(p) => p.theme.color.grey[400]};
  color: ${(p) => p.theme.color.grey[800]};
  font-size: ${(p) => p.theme.typography.size.base};
  padding: ${(p) => p.theme.gap.S} ${rem('15px')};

  :disabled,
  :read-only {
    background: ${(p) => p.theme.color.grey[100]};
  }

  &::placeholder {
    color: ${(p) => p.theme.color.grey[400]};
    font-size: ${(p) => p.theme.typography.size.base};
    font-weight: 300;
  }

  :invalid {
    border-color: red;
  }
`;

export default Input;
